const introCaptions = {};

introCaptions.line0 = "20XX/05/1X";
introCaptions.line1 = "I am pretty much fucked";
introCaptions.line2 = "Left alone in the space";
introCaptions.line3 = "Time left...";
introCaptions.line4 = "42 minute";

const endingLine = "42 space simulator";

const counterCaptions = {};
counterCaptions.line1 = "So this is the end.";
counterCaptions.line2 = "Far from home.";
counterCaptions.line3 = "Maybe i should stay at home.";
counterCaptions.line4 = "Or maybe i should't buy those doge coin";
counterCaptions.line5 = "I'm getting cold.";
counterCaptions.line6 = "Time to go";

const emptyLine = "";

export { emptyLine, introCaptions, counterCaptions, endingLine };
